._1nRxaaMwwnYCs3n_7_iXWF {
  width: 32px;
  height: 32px;
  top: 2rem;
  left: 1rem;
}

._1BJh-4QblpWcfhOIRUb0tt .PnPJBVjTOCZwIhJMb4fKq > button {
  padding: 0 !important; /*Нейтрализация паддингов у Dropdown Button*/
}

._3Djc4bT5n8_EdlMyUxExm4 img {
  max-width: 10.375rem;
  height: 1.375rem;
}

._2QNRTB-axOZThyekIR_Jeo {
  margin-top: 9rem;
}

._23hNT5TD3v-sCc1t8T4rb8 {
  width: 7rem;
  height: 7rem;
}

._3SoW1DRgisQsdn7PwXiGpZ {
  background-color: var(--gray-200);
}

._1XvlnDXBuv1_59si1rYOaE {
  font-size: 0;
}

._1XvlnDXBuv1_59si1rYOaE:last-child {
  margin-bottom: 0 !important;
}

.aBT2pgC1as_6diL0PyYbH {
  width: 100%;
  height: auto;
}

._1-tWgYzNcTfwdSINNrSxTl {
  width: 100%;
  max-width: 65.25rem;
}

._1eihzwz9e0faCcD50lYSDE {
  width: 100%;
  max-width: 43.75rem;
}

._1-tWgYzNcTfwdSINNrSxTl,
._1eihzwz9e0faCcD50lYSDE {
  -webkit-filter: drop-shadow(0px 4px 16px rgba(0, 0, 0, 0.2));
          filter: drop-shadow(0px 4px 16px rgba(0, 0, 0, 0.2));
}

._3Xdc2nSg-XCK58n_DstF8k {
  background-color: var(--light);
  padding-top: 141.4%;
  /*Соотношение сторон листа A4.
   Формула расчета:
     297 / 210 ≈ 1.414
     1.414 * 100% = 141.4%

   Нужно для того, чтобы плейсхолдер сохранял пропорции при изменении ширины.
  */
}

._3Xdc2nSg-XCK58n_DstF8k._2pKYiaxak6ICcE90Sgmz2 {
  background-image: url('/assets/img/document.svg');
  background-repeat: no-repeat;
  background-size: 48px 55px;
  background-position: top 45% center;
}

._2npwN3i5WRZnzdUnEseUXA {
  width: 100%;
  height: 100%;
}

._2ok4kIZdrJfh8DB1_eeRGs {
  width: 3rem;
  height: 3rem;
  margin-top: 50%;
}

.GFTkMFlsscmNeCnhSElXH {
  text-align: center;
}

._3Xdc2nSg-XCK58n_DstF8k._2pKYiaxak6ICcE90Sgmz2 .GFTkMFlsscmNeCnhSElXH {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
}

.bddrMGjOZnMvU0V1pPpKa {
  left: 0;
  right: 0;
  bottom: 0;
  height: var(--footer-height);
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.2);
}

._1izY3loa5h3zP2lh24hgUB {
  width: calc(50% - var(--space-xs));
}

@media screen and (min-width: 768px) {
  ._1izY3loa5h3zP2lh24hgUB {
    width: auto;
  }
}

._1kPCA6cjou6fQgZl3_0mLE {
  border-left: none !important;
}

._1JfSFfQWRegMUmdLlH-BDs:last-child ._3ru4BNhmW0VrMElrDD87Z9::before {
  display: none;
}

._3ru4BNhmW0VrMElrDD87Z9::before {
  content: '';
  position: absolute;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  top: var(--space-xl);
  bottom: calc(-1 * var(--space-xl));
  width: 1px;
  background-color: var(--gray-100);
}

._2WY8_zrOiljU9dvoNaSMEq {
  height: calc(100% - var(--header-height) - var(--footer-height)) !important;
}

._1usb_DXctjlwcY2ZRedUyM {
  border-bottom: 1px solid var(--gray-200);
}

._3mhJNil9YHm2BUM9IDH7LH::before,
._2SbExEy_rLEt2ykShdN8I8::before {
  content: '';
  position: absolute;

  top: 0.75rem;
  right: 0;
  left: 0;
  z-index: -1;

  border-bottom: 1px dotted var(--gray-200);
}

._3mhJNil9YHm2BUM9IDH7LH label,
._3mhJNil9YHm2BUM9IDH7LH p,
._2SbExEy_rLEt2ykShdN8I8 label,
._2SbExEy_rLEt2ykShdN8I8 p {
  max-width: 50%;
}

._3mhJNil9YHm2BUM9IDH7LH span,
._2SbExEy_rLEt2ykShdN8I8 span {
  background-color: var(--light);
}

@media screen and (min-width: 768px) {
  ._3mhJNil9YHm2BUM9IDH7LH::before,
  ._2SbExEy_rLEt2ykShdN8I8::before {
    display: none;
  }

  ._3mhJNil9YHm2BUM9IDH7LH span,
  ._2SbExEy_rLEt2ykShdN8I8 span {
    background: none;
  }

  ._3mhJNil9YHm2BUM9IDH7LH label,
  ._3mhJNil9YHm2BUM9IDH7LH p,
  ._2SbExEy_rLEt2ykShdN8I8 label,
  ._2SbExEy_rLEt2ykShdN8I8 p {
    max-width: none;
  }

  ._2SbExEy_rLEt2ykShdN8I8 label {
    width: 9rem;
  }

  ._2SbExEy_rLEt2ykShdN8I8 p {
    width: 18rem;
  }
}

._3gkAyuDEuiL9iqTFeCCNCT {
  height: 100%;
  max-height: 100%;
}

:root {
  --footer-height: 3rem
}

html {
  height: -webkit-fill-available;
}

body {
  color: var(--text-color);
  height: 100%;
}

.inner {
  min-width: 320px;
  max-width: 1200px;
}

@media screen and (min-width: 768px) {
  :root {
    --footer-height: 3.5rem
  }
}

