.footer {
  left: 0;
  right: 0;
  bottom: 0;
  height: var(--footer-height);
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.2);
}

.button {
  width: calc(50% - var(--space-xs));
}

@media screen and (min-width: 768px) {
  .button {
    width: auto;
  }
}
