:root {
  --footer-height: 3rem
}

html {
  height: -webkit-fill-available;
}

body {
  color: var(--text-color);
  height: 100%;
}

.inner {
  min-width: 320px;
  max-width: 1200px;
}

@media screen and (min-width: 768px) {
  :root {
    --footer-height: 3.5rem
  }
}
