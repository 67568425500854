.container {
  background-color: var(--gray-200);
}

.image_wrap {
  font-size: 0;
}

.image_wrap:last-child {
  margin-bottom: 0 !important;
}

.image {
  width: 100%;
  height: auto;
}

.landscape {
  width: 100%;
  max-width: 65.25rem;
}

.portrait {
  width: 100%;
  max-width: 43.75rem;
}

.landscape,
.portrait {
  filter: drop-shadow(0px 4px 16px rgba(0, 0, 0, 0.2));
}

.placeholder {
  background-color: var(--light);
  padding-top: 141.4%;
  /*Соотношение сторон листа A4.
   Формула расчета:
     297 / 210 ≈ 1.414
     1.414 * 100% = 141.4%

   Нужно для того, чтобы плейсхолдер сохранял пропорции при изменении ширины.
  */
}

.placeholder.missing {
  background-image: url('/assets/img/document.svg');
  background-repeat: no-repeat;
  background-size: 48px 55px;
  background-position: top 45% center;
}

.placeholder_content {
  width: 100%;
  height: 100%;
}

.placeholder_spinner {
  width: 3rem;
  height: 3rem;
  margin-top: 50%;
}

.placeholder_text {
  text-align: center;
}

.placeholder.missing .placeholder_text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%);
}
