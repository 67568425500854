.comment {
  border-left: none !important;
}

.item:last-child .icon::before {
  display: none;
}

.icon::before {
  content: '';
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: var(--space-xl);
  bottom: calc(-1 * var(--space-xl));
  width: 1px;
  background-color: var(--gray-100);
}
