.content {
  height: calc(100% - var(--header-height) - var(--footer-height)) !important;
}

.title {
  border-bottom: 1px solid var(--gray-200);
}

.additional_field::before,
.field::before {
  content: '';
  position: absolute;

  top: 0.75rem;
  right: 0;
  left: 0;
  z-index: -1;

  border-bottom: 1px dotted var(--gray-200);
}

.additional_field label,
.additional_field p,
.field label,
.field p {
  max-width: 50%;
}

.additional_field span,
.field span {
  background-color: var(--light);
}

@media screen and (min-width: 768px) {
  .additional_field::before,
  .field::before {
    display: none;
  }

  .additional_field span,
  .field span {
    background: none;
  }

  .additional_field label,
  .additional_field p,
  .field label,
  .field p {
    max-width: none;
  }

  .field label {
    width: 9rem;
  }

  .field p {
    width: 18rem;
  }
}
